import React from 'react';

import * as QueryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { setIsLandscape } from '../common/helpers/misc';
import { useVerifyGameSetting } from '../services/userService';
import CommonHandler from './third-party-redirect/CommonHandler';

const Launch = ({ location }) => {
    const params = QueryString.parse(location.search);

    const { response, error } = useVerifyGameSetting(params);

    // Didn't check for isSaba when hit error in verify game setting. Prevent to pass isSaba as param
    // As very less chances will hit error, unless the link is expired or user manually alter the link,
    if (response) setIsLandscape(response.data.result.isSaba);

    if (error) {
        return (
            <CommonHandler
                hasError={true}
                url={params.backUrl}
                theme={error.data.result.theme ?? 'blue'}
            />
        );
    }

    if (response) {
        import(`${'../stores/GameSettingsStore'}`).then((module) => {});
        return <Redirect to={'/'} />;
    }

    return <div></div>;
};

export default Launch;
