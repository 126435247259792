import React, { Suspense, lazy } from 'react';

import { AppConstants } from '../../constants/AppConstants';
import { Clib } from '../../game-logic/clib';
import { Image } from '../Image';
import { getIsLandscape, isChina } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';
import ErrorPage from '../ErrorPage';
import Maintenance from '../Maintenance';

export default ({ hasError, hasMaintenance, url, theme, errorCode = null, language = 'en-US' }) => {
    const _cssRoot = AppConstants.LOCAL_PATH.CSS.ROOT;
    const _cdnCss = AppConstants.LOCAL_PATH.CSS.CDN_FILE;
    const _isLandscape = getIsLandscape();

    const MyComponent = lazy(() =>
        import('../internationalisation/IntlProvider').then((module) => ({
            default: module.IntlProvider,
        })),
    );

    const loadThemeCss = (theme) => {
        if (theme && theme !== '') {
            var themeFile = _cssRoot + theme + 'Theme.css';
            Clib.loadCss(themeFile, 'theme-' + theme);

            if (isChina()) {
                Clib.loadCss(_cdnCss + theme + 'Theme.css', 'theme-' + theme + '-cdn');
            }
        }

        if (_isLandscape) {
            Clib.loadCss(_cssRoot + 'landscapeTheme.css', 'theme-landscape');

            if (isChina()) {
                Clib.loadCss(_cdnCss + 'landscapeTheme.css', 'theme-landscape-cdn');
            }
        }
    };

    loadThemeCss(theme);

    return (
        <>
            {
                <Suspense fallback={<div>Loading...</div>}>
                    {MyComponent && (
                        <MyComponent language={language}>
                            <div id="game-wrapper">
                                <div id="game-content" className="redirect-page-bg">
                                    <div id="game-container">
                                        <div id="game-inner-container">
                                            {!_isLandscape && (
                                                <div id="top-bar" className="redirect-page-shadow">
                                                    <div className="title">
                                                        {url && (
                                                            <a className="back" href={url}>
                                                                <Image
                                                                    src="back-icon.svg"
                                                                    alt="back"
                                                                />
                                                            </a>
                                                        )}

                                                        <a href={url}>
                                                            <h1>{getTranslation('CRASH')}</h1>
                                                        </a>
                                                    </div>
                                                </div>
                                            )}

                                            <div id="loading-container">
                                                <div id="loading-image">
                                                    {hasError && (
                                                        <ErrorPage
                                                            theme={theme}
                                                            sessionInvalid={errorCode === 401}
                                                        />
                                                    )}
                                                    {hasMaintenance && (
                                                        <Maintenance theme={theme} />
                                                    )}
                                                </div>
                                            </div>

                                            <div id="handheld-detection"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MyComponent>
                    )}
                </Suspense>
            }
        </>
    );
};
