import _ from 'lodash';

import { AppConstants } from '../constants/AppConstants';
import { AppDispatcher } from '../dispatcher/AppDispatcher';
import { Clib } from '../game-logic/clib';
import { isChina } from '../common/helpers/misc';
import Events from '../lib/events';

var CHANGE_EVENT = 'change';

var _themeFileName = '/assets/css/' + (window.THEME_FILE_NAME || 'blackTheme.css');

var _currentTheme = Clib.localOrDef('currentTheme', 'blue');

var _controlsSize = Clib.localOrDef('controlsSize', 'big');
var _graphMode = Clib.localOrDef('graphMode', 'graphics');
var _controlsPosition = Clib.localOrDef('controlsPosition', 'right');
var _leftWidget = Clib.localOrDef('leftWidget', 'players');
var _user_message = { type: '', message: '' };
var _hotkeysActive = false;

const _cssRoot = AppConstants.LOCAL_PATH.CSS.ROOT;
const _cdnCss = AppConstants.LOCAL_PATH.CSS.CDN_FILE;

var _soundEnable = false;

var _ignoredClientList = JSON.parse(Clib.localOrDef('ignoredList', '{}'));

export var GameSettingsStore = _.extend({}, Events, {
    emitChange: function () {
        this.trigger(CHANGE_EVENT);
    },

    addChangeListener: function (callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function (callback) {
        this.off(CHANGE_EVENT, callback);
    },

    _toggleTheme: function () {
        if (_currentTheme === 'white') {
            Clib.loadCss(_themeFileName, 'theme-black');
            _currentTheme = 'black';
        } else {
            Clib.removeCss('theme-black');
            _currentTheme = 'white';
        }
    },

    _setTheme: function (theme = 'blue') {
        _currentTheme = theme;
        Clib.removeCss('theme-black');
        Clib.removeCss('theme-rocket');
        var themeFile = _cssRoot + theme + 'Theme.css';
        Clib.loadCss(themeFile, 'theme-' + theme);

        if (isChina()) {
            Clib.removeCss('theme-black-cdn');
            Clib.removeCss('theme-rocket-cdn');
            Clib.loadCss(_cdnCss + theme + 'Theme.css', 'theme-' + theme + '-cdn');
        }
    },

    _setGraphMode: function (graphMode) {
        _graphMode = graphMode;
        localStorage['graphMode'] = graphMode;
    },

    _setControlsSize: function (controlsSize) {
        _controlsSize = controlsSize;
        localStorage['controlsSize'] = controlsSize;
    },

    _toggleHotkeysState: function () {
        _hotkeysActive = !_hotkeysActive;
    },

    _toggleSound: function () {
        _soundEnable = !_soundEnable;
    },

    _ignoreUser: function (username) {
        _ignoredClientList[username.toLowerCase()] = { username: username };
        localStorage['ignoredList'] = JSON.stringify(_ignoredClientList);
    },

    _approveUser: function (username) {
        username = username.toLowerCase();
        if (_ignoredClientList[username]) {
            delete _ignoredClientList[username];
            localStorage['ignoredList'] = JSON.stringify(_ignoredClientList);
        }
    },

    _userMessage: function (type, message) {
        _user_message.type = type;
        _user_message.message = message;
    },

    getState: function () {
        return {
            graphMode: _graphMode,
            controlsSize: _controlsSize,
            controlsPosition: _controlsPosition,
            leftWidget: _leftWidget,
            hotkeysActive: _hotkeysActive,
            user_message: _user_message,
            soundEnable: _soundEnable,
        };
    },

    getCurrentTheme: function () {
        return _currentTheme;
    },

    getIgnoredClientList: function () {
        return _ignoredClientList;
    },

    getSoundEnable: function () {
        return _soundEnable;
    },
});

AppDispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
        case AppConstants.ActionTypes.TOGGLE_THEME:
            GameSettingsStore._toggleTheme();
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.SET_THEME:
            GameSettingsStore._setTheme(action.theme);
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.SET_CONTROLS_SIZE:
            GameSettingsStore._setControlsSize(action.controlsSize);
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.SET_GRAPH_MODE:
            GameSettingsStore._setGraphMode(action.graphMode);
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.TOGGLE_HOYTKEYS_STATE:
            GameSettingsStore._toggleHotkeysState();
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.IGNORE_USER:
            GameSettingsStore._ignoreUser(action.username);
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.APPROVE_USER:
            GameSettingsStore._approveUser(action.username);
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.USER_MESSAGE:
            GameSettingsStore._userMessage(action.type, action.message);
            GameSettingsStore.emitChange();
            break;

        case AppConstants.ActionTypes.TOGGLE_SOUND:
            GameSettingsStore._toggleSound();
            GameSettingsStore.emitChange();
            break;

        default:
            break;
    }

    return true;
});
