export const LANGUAGES = [
    {
        country: 'cn',
        name: '简体中文',
        value: 'zh-CN',
    },
    {
        country: 'my',
        name: 'English',
        value: 'en-US',
    },
    {
        country: 'vn',
        name: 'Vietnam',
        value: 'vi-VN',
    },
    {
        country: 'id',
        name: 'Indonesia',
        value: 'id-ID',
    },
    {
        country: 'th',
        name: 'Thailand',
        value: 'th-TH',
    },
    // {
    //     country: 'tw',
    //     name: '繁體中文',
    //     value: 'zh-TW',
    // },
    // {
    //     country: 'jp',
    //     name: '日本語',
    //     value: 'ja-JP',
    // },
    {
        country: 'kr',
        name: '한국어',
        value: 'ko-KR',
    },
    {
        country: 'in',
        name: 'भारत',
        value: 'hi-IN',
    },
];
