function Subs() {
    this.subs = [];

    Subs.prototype.subscribe = function(self) {
        this.subs.push(self);
    }

    Subs.prototype.broadcast = function(self, location) {
        for (var x in this.subs) {
            this.subs[x].on(location);
        }
    }
}

var SubService = new Subs();

export default SubService;