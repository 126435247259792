import React from 'react';

import PropTypes from 'prop-types';

import { GameSettingsStore } from '../stores/GameSettingsStore';
import { Image } from './Image';
import { THEMES } from '../common/constants/misc';
import { getTranslation } from '../common/helpers/locale';
import { isDesktop } from '../common/helpers/misc';
import GameSettingsActions from '../actions/GameSettingsActions';
import MComponent from '../../views/MComponent';

function getState() {
    return {
        theme: GameSettingsStore.getCurrentTheme(), //black || white
    };
}

export class TopBar extends MComponent {
    constructor(props) {
        super(props);
        this.state = getState();
        this.state.fullScreen = false;
    }

    static propTypes = {
        isMain: PropTypes.bool.isRequired,
        // isMobileOrSmall: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        hideMenus: false,
    };

    componentDidMount() {
        GameSettingsStore.on('all', this._onChange);
    }

    componentWillUnmount() {
        GameSettingsStore.off('all', this._onChange);
    }

    _onChange = () => {
        this.setState(getState());
    };

    _toggleTheme = () => {
        GameSettingsActions.toggleTheme();
    };

    _toggleFullScreen = () => {
        window.screenfull.toggle();
        this.setState({ fullScreen: !this.state.fullScreen });
    };

    _toggleSound = () => {
        GameSettingsActions.toggleSound();
    };

    _showHelpModel = () => {
        GameSettingsActions.userMessage('desktop-help', '');
    };

    back = () => {
        if (this.props.back_url && this.props.back_url !== undefined) {
            window.location = this.props.back_url;
        } else {
            this._goBack();
        }
    };

    render() {
        var back =
            this.props.back_url && this.props.back_url !== null ? (
                <div className="back" onClick={this.back}>
                    <Image src="back-icon.svg" alt="back" />
                </div>
            ) : (
                <div className="back"></div>
            );
        return (
            <div id="top-bar">
                <div className="title">
                    {back}
                    {/* { (this.props.isMain) ? userLogin : '' } */}
                    <a href={this.props.title_url ? this.props.title_url : '/'}>
                        <div
                            className={`title-container  ${
                                this.props.title === getTranslation('CRASH') ? 'main-title' : ''
                            }`}
                        >
                            {(this.props.theme ?? this.state.theme) ===
                                THEMES.BLUE_THEME.EURO2020 &&
                            this.props.title === getTranslation('CRASH') ? (
                                <>
                                    <h1>{this.props.title} x</h1>
                                    <Image src="euro/euro-logo.png" alt="Euro" />
                                </>
                            ) : isDesktop() ? (
                                <>
                                    <Image src={(this.props.theme ?? this.state.theme) === THEMES.OKBET ? `okbet/flash_logo.png` : `flash_logo.png`} alt="logo" />
                                    <h1>x {this.props.title}</h1>
                                </>
                            ) : (
                                <h1>{this.props.title}</h1>
                            )}
                        </div>
                    </a>

                    <div className="menu">
                        {isDesktop() && !this.props.hideMenus && (
                            <>
                                <div onClick={this._toggleSound}>
                                    <Image
                                        src={`desktop/${
                                            GameSettingsStore.getState().soundEnable
                                                ? 'sound-on.png'
                                                : 'sound-off.png'
                                        }`}
                                        alt="home"
                                    />
                                </div>
                                <div onClick={this._showHelpModel}>
                                    <Image
                                        src={
                                            this.props.isLandscape
                                                ? 'landscape/help.png'
                                                : 'bottom-help.svg'
                                        }
                                        alt="help"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
