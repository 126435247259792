import React, { Fragment, useEffect, useState } from 'react';

import intl from 'react-intl-universal';

import {
    getBrowserLocale,
    getLanguageByCode,
    getSupportedLocales,
} from '../../common/helpers/locale';

// NOTE: Only use for old verify while having error/maintenance.
// Set intl will in the Game.jsx.
export const IntlProvider = (props) => {
    const [initDone, setInitDone] = useState(false);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        const currentlanguage = props.language === undefined ? 'en-US' : props.language;
        const countryFound = getLanguageByCode(currentlanguage);
        const browserLocale = getBrowserLocale().indexOf('zh') !== -1 ? 'zh-CN' : 'en-US';

        intl.init({
            currentLocale: countryFound ? currentlanguage : browserLocale,
            locales: getSupportedLocales(),
        }).then(() => {
            setInitDone(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ============================================
    // RENDER
    // ============================================

    return initDone && <Fragment>{props.children}</Fragment>;
};
