import React, { useEffect, useState } from 'react';

import * as QueryString from 'query-string';
import * as timeago from 'timeago.js';
import { hi_IN, id_ID, ko, th, vi } from 'timeago.js/lib/lang';
import intl from 'react-intl-universal';
import moment from 'moment';

import { AppConstants } from '../constants/AppConstants';
import { Clib } from '../game-logic/clib';
import { TopBar } from './TopBar';
import { getSupportedLocales, getTranslation, validLanguageCheck } from '../common/helpers/locale';
import { isChina } from '../common/helpers/misc';
import { useGetGameResult } from '../services/sabaService';
import ErrorPage from './ErrorPage';

const Result = ({ location }) => {
    const [values, setValues] = useState({});
    const [isVoid, setIsVoid] = useState(false);
    const [isOnGoing, setIsOnGoing] = useState(false);
    const [language, setLanguage] = useState('en-US');
    const cssRoot = AppConstants.LOCAL_PATH.CSS.ROOT;
    const cdnCss = AppConstants.LOCAL_PATH.CSS.CDN_FILE;
    const params = QueryString.parse(location.search);

    // ============================================
    // METHODS
    // ============================================

    function initLoad() {
        setLanguage(validLanguageCheck(params.LangCode));

        Clib.loadCss(cssRoot + 'result.css', 'result');
        if (isChina()) {
            Clib.loadCss(cdnCss + 'result.css', 'result-cdn');
        }

        intl.init({
            currentLocale: language,
            locales: getSupportedLocales(),
        });
    }

    function matchLanguage(currentLanguage) {
        switch (currentLanguage) {
            case 'vi-VN':
                timeago.register('vi', vi);
                return 'vi';
            case 'th-TH':
                timeago.register('th', th);
                return 'th';
            case 'zh-CN':
                return 'zh_CN';
            case 'id-ID':
                timeago.register('id_ID', id_ID);
                return 'id_ID';
            case 'hi-IN':
                timeago.register('hi_IN', hi_IN);
                return 'hi_IN';
            case 'ko-KR':
                timeago.register('ko_KR', ko);
                return 'ko_KR';
            default:
                return '';
        }
    }

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useGetGameResult(params);

    useEffect(() => {
        initLoad();
    });

    useEffect(() => {
        if (response) {
            setIsVoid(response.data.status === 'VOIDED');
            setIsOnGoing(response.data.status === 'STARTED');
            setValues({
                localDate: response.data.startDate,
                date: moment.utc(response.data.startDate).format('DD/MM/yyyy'),
                time: moment.utc(response.data.startDate).format('HH:mm:ss'),
                gameSessionId:
                    moment.utc(response.data.startDate).format('yyyyMMDD') +
                    response.data.gameSessionIndex,
                result: isVoid ? response.data.result : response.data.result / 100,
            });
        }
    }, [response, isVoid]);

    // ============================================
    // RENDER
    // ============================================

    const Result = () => (
        <div className="result-modal">
            <span className="game-id">
                {getTranslation('GAME_ID')} {values.gameSessionId}
            </span>

            <div className={`result-content ${isVoid || isOnGoing ? 'void' : ''}`}>
                <span className={`result-crashed ${isVoid || isOnGoing ? 'void' : ''}`}>
                    {isVoid ? (
                        getTranslation('VOID')
                    ) : isOnGoing ? (
                        getTranslation('ONGOING')
                    ) : (
                        <>
                            {getTranslation('CRASHED')} @ <br />
                            <br />
                            <span className="result-crashed-at">{values.result}x</span>
                        </>
                    )}
                </span>

                <div className="result-date-time">
                    <span className="result-date">
                        {getTranslation('DATE')}: <br /> {values.date}
                    </span>

                    <span className="result-time">
                        {getTranslation('TIME')}: <br /> {values.time} GMT
                        <span className="result-time-ago">
                            {timeago.format(values.localDate, matchLanguage(language))}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );

    return (
        <div id="game-wrapper">
            <div id="game-content">
                <div id="game-container">
                    <div id="game-inner-container">
                        <TopBar
                            isMobileOrSmall={true}
                            title={getTranslation('TRANSACTIONS')}
                            back_url={null}
                            isMain={false}
                            title_url={window.location.href}
                        />

                        <div className="result-container">
                            {loading ? null : error ? <ErrorPage theme="blue" /> : <Result />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Result;
