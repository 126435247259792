import APIService from '../services/api.service';
import MiddlewareService from '../services/middleware.service';
import React from 'react';
import SubsService from '../services/subs.service';

class MComponent extends React.Component {
    constructor(props) {
        super(props);

        this.services = {
            middleware: MiddlewareService,
            api: APIService,
            subs: SubsService,
        };
    }
    mounted = false;

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    _register = (self) => {
        _render = self;
    };

    _goBack = () => {
        if (_render) {
            _render.setState({ _url: 'home' });
        }
    };

    _goToUrl = (url) => {
        _render.setState({ _url: url });
    };

    _getNavigation = () => {
        return _render.state._url;
    };

    broadcast = (location) => {
        this.services.subs.broadcast(this, location);
    };

    // setState = (state, callback) => {
    //     if (this.mounted) {
    //         return super.setState(state, callback);
    //     }else{
    //         console.error("dismounted, skipping state update");
    //     }
    // }

    _redirect = (url, authorize = false) => {
        if (authorize) {
            var authorized = false;
            this.services.middleware.authorize(this, false, function (err, res) {
                if (err) {
                    return;
                }
                authorized = true;
            });
            if (!authorized) {
                return;
            }
        }
        this._goToUrl(url);
    };
}
var _render = '';

export default MComponent;
