import React from 'react';

import { AppConstants } from '../constants/AppConstants';
import { getImageUrl } from '../common/helpers/misc';

export const Image = ({ src, alt = '', ...rest }) => {
    const localImage = AppConstants.LOCAL_PATH.IMAGE + src;

    return (
        <img
            src={getImageUrl(src, false)}
            onError={(e) => {
                e.target.onerror = null;
                if (!e.currentTarget.src.includes(localImage)) e.target.src = localImage;
            }}
            alt={alt}
            {...rest}
        />
    );
};
