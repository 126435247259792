import { AppConstants } from '../constants/AppConstants';
import Seedrandom from 'seedrandom';
import _ from 'lodash';

function formatSatoshis(n, decimals) {
    return formatDecimals(n / 100, decimals);
}

function formatDecimals(n, decimals) {
    if (typeof decimals === 'undefined') {
        if (n % 100 === 0) {
            decimals = 0;
        } else {
            decimals = 2;
        }
    }
    return n
        .toFixed(decimals)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const Clib = {
    calcGamePayout: function (ms) {
        var gamePayout = this.growthFunc(ms);
        console.assert(isFinite(gamePayout));
        return gamePayout;
    },

    capitaliseFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatDecimals: formatDecimals,

    formatSatoshis: formatSatoshis,

    getCurrencyMultiples: function (currency) {
        switch (currency) {
            case 'VND':
            case 'IDR':
            case 'VND-F2P':
            case 'IDR-F2P':
                return 1000;

            default:
                return 1;
        }
    },

    getDay: function (val) {
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[val];
    },

    getDisplayAmountByCurrency: function (currency, amount) {
        return amount;
        // return parseFloat(amount) / this.getCurrencyMultiples(currency);
    },

    getElapsedTime: function (startTime) {
        return Date.now() - startTime;
    },

    getElapsedTimeWithLag: function (engine) {
        if (engine.gameState === 'IN_PROGRESS') {
            var elapsed;
            if (engine.lag) {
                elapsed =
                    engine.lastGameTick -
                    engine.startTime +
                    AppConstants.Engine.STOP_PREDICTING_LAPSE;
            } else {
                elapsed = this.getElapsedTime(engine.startTime);
            }

            return elapsed;
        } else {
            return 0;
        }
    },

    getRealAmountByCurrency: function (currency, amount) {
        return amount;
        // return amount * this.getCurrencyMultiples(currency);
    },

    getRealCurrency: function (currency) {
        return currency.replace('-F2P', '');
    },

    grammarBits: function (bits) {
        return bits <= 100 ? 'bit' : 'bits';
    },

    growthFunc: function (ms) {
        console.assert(typeof ms == 'number' && ms >= 0);
        var r = 0.00006;
        return Math.floor(100 * Math.pow(Math.E, r * ms)) / 100;
    },

    houseExpectedReturn: function (amount, cashOut) {
        var p1, p2, p3;
        var v1, v2, v3;

        p1 = 1 / 101;
        v1 = amount;

        p2 = this.winProb(amount, cashOut);
        v2 = -0.01 * amount - this.profit(amount, cashOut);

        p3 = 1 - p1 - p2;
        v3 = 0.99 * amount;

        return p1 * v1 + p2 * v2 + p3 * v3;
    },

    isInteger: function (nVal) {
        return (
            typeof nVal === 'number' &&
            isFinite(nVal) &&
            nVal > -9007199254740992 &&
            nVal < 9007199254740992 &&
            Math.floor(nVal) === nVal
        );
    },

    isInvalidUsername: function (username) {
        if (typeof username !== 'string') {
            return 'NOT_STRING';
        }
        if (username.length === 0) {
            return 'NOT_PROVIDED';
        }
        if (username.length < 3) {
            return 'TOO_SHORT';
        }
        if (username.length > 50) {
            return 'TOO_LONG';
        }
        if (!/^[a-z0-9_-]*$/i.test(username)) {
            return 'INVALID_CHARS';
        }
        if (username === '__proto__') {
            return 'INVALID_CHARS';
        }
        return false;
    },

    isMobileOrSmall: function () {
        if (!document.getElementById('handheld-detection')) {
            return false;
        }
        return (
            window.getComputedStyle(document.getElementById('handheld-detection'), null).display ===
            'none'
        );
    },

    isNumber: function (nVal) {
        return (
            typeof nVal === 'number' &&
            isFinite(nVal) &&
            nVal > -9007199254740992 &&
            nVal < 9007199254740992
        );
    },

    loadCss: function (url, id) {
        var link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;
        link.id = id;
        document.body.appendChild(link);
    },

    localOrDef: function (name, defaultValue) {
        return localStorage[name] ? localStorage[name] : defaultValue;
    },

    parseAutoCash: function (autoCashString) {
        var co = autoCashString;

        if (!/^\d+(\.\d{1,2})?$/.test(co)) {
            return new Error('Invalid auto cash out amount');
        }

        co = parseFloat(co);
        console.assert(!_.isNaN(co));

        if (co < 1) {
            return new Error('The auto cash out amount should be bigger than 1');
        }

        return co;
    },

    parseBet: function (betString) {
        betString = String(betString);

        if (!/^\d+k*$/.test(betString)) {
            return new Error('Bet may only contain digits, and k (to mean 1000)');
        }

        var bet = parseInt(betString.replace(/k/g, '000'));

        if (bet < 1) {
            return new Error('The bet should be at least 1 bit');
        }

        if (bet * 100 > AppConstants.Engine.MAX_BET) {
            return new Error(
                'The bet must be less no more than ' +
                    formatSatoshis(AppConstants.Engine.MAX_BET) +
                    ' bits',
            );
        }

        if (_.isNaN(bet) || Math.floor(bet) !== bet) {
            return new Error('The bet should be an integer greater than or equal to one');
        }

        return bet;
    },

    payout: function (betSize, ms) {
        return betSize * Math.pow(Math.E, 0.00006 * ms);
    },

    payoutTime: function (betSize, payout) {
        return Math.log(payout / betSize) / 0.00006;
    },

    profit: function (amount, cashOut) {
        var factor = Math.ceil((100 * cashOut) / amount);

        return (amount * (factor - 100)) / 100;
    },

    removeCss: function (id) {
        var el = document.getElementById(id);
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    },

    seed: function (newSeed) {
        Seedrandom(newSeed);
    },

    winProb: function (amount, cashOut) {
        var factor = Math.ceil((100 * cashOut) / amount);
        return 9900 / (101 * (factor - 1));
    },
};
