// "use strict";
import {invariant} from '../lib/invariant';

export default function keyMirror(obj) {
    var ret = {};
    var key;
    invariant(obj instanceof Object && !Array.isArray(obj));
    for (key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }
      ret[key] = key;
    }
    return ret;
  };