import async from 'async';
import { AppConstants } from '../scripts/constants/AppConstants';
import Lib from '../server/lib';

function Middleware() {
    this.profile = null;

    Middleware.prototype.getProfile = function(self, parent, callback) {
        self.profile = Lib.getProfile();
        if (!localStorage.getItem(AppConstants.PROFILE_ID)) {
            // window.isTrusted = false;
            // if (!self.profile || Lib.timeDifference(new Date(), new Date(self.cookie.expires)) < 0) {
            //     callback("invalid_profile", null);
            //     return;
            // }else{
            //     window.profileId = (typeof localStorage !== "undefined")? self.profile.id : self.profile;
            // }
            // var data = {
            //     sessionId: window.profileId,
            //     isTrusted: window.isTrusted
            // }
            // var url = APIService.path('auth/authorize');
            // axios.post(url, data).then(res => {
            //     if (res.status === 200) {
            //         let data = res.data;
            //         window.engine_user = data;
            //         callback(null, window.engine_user);
            //     }else{
            //         console.log("authorize ERROR: " + res);
            //         Lib.remoevProfile();
            //         callback(res, null);
            //     }
            // });
        }else{
            window.isTrusted = true;
            if (typeof localStorage !== "undefined") {
                window.profileId = self.profile;
                callback(null, "SUCCESS");
            }else{
                callback("ERROR", null);
            }
        }
    }

    Middleware.prototype.authorize = (self, redirect=false, callback=null) => {
        async.parallel([this.getProfile.bind(null, this, self)], function(err, results) {
            if (err) {
                console.error("ERROR ", err);
                if (redirect) {
                    self._goToUrl('login');
                }
            }
            if (typeof callback === 'function') callback(err, results);
        });
    }
}

var MiddleWare = new Middleware();

export default MiddleWare;