export const STORAGE = {
    ANONYMOUS: 'anonymous',
    LANGUAGE: 'language',
    THEME: 'currentTheme',
    USER: 'id',
    GAMEID: 'gameId',
    MINBUY: 'minBuy',
    MAXBUY: 'maxBuy',
    INCREMENT: 'increment',
    QUICKBUYBUTTON1: 'quickBuyButton1',
    QUICKBUYBUTTON2: 'quickBuyButton2',
    QUICKBUYBUTTON3: 'quickBuyButton3',
    QUICKBUYBUTTON4: 'quickBuyButton4',
    ENABLEAUTOSELL: 'enableAutoSell',
    MAXAUTOSELL: 'maxAutoSell',
    CURRENCY: 'currency',
    CURRENCY_CODE: 'currencyCode',
    BACKURL: 'backUrl',
    SIGNUPURL: 'signUpUrl',
    LOGINURL: 'loginUrl',
    ACCOUNT_LOCKED: 'accountLocked',
    CURRENCY_MULTIPLIER: 'currencyMultiplier',
    LANDSCAPE: 'landscape',
    ISCHINA: 'isChina',
    MAXPAYOUT: 'maxPayout',
    DEFAULT_BUY: 'defaultBuy',
};
