import { API } from '../common/constants/api';
import { HTTP_HEADER } from '../common/constants/misc';
import { ROUTES } from '../common/constants/routing';
import { STORAGE } from '../common/constants/keys';
import { configsService } from './configsService';
import {
    deleteFromLocalStorage,
    getLocalStorage,
    setLocalStorage,
} from '../common/helpers/localStorage';
import { useEffect } from 'react';
import useApi from './hooks/useApi';

const { APIBASE, CLIENT, HOST_GAME_LAUNCHER } = API;

export function useVerifyThirdParty(payload, refererUrl, pathname) {
    let oldSessionId = getLocalStorage(STORAGE.USER);
    if (oldSessionId === undefined) {
        oldSessionId = '';
    }
    let oldCurrency = undefined;
    if (oldCurrency === undefined) {
        oldCurrency = '';
    }

    let platform = payload.platform;
    if (platform === undefined) {
        platform = '';
    }

    let URL = undefined;
    if (pathname === ROUTES.CLIENT.AUTH) {
        URL = `${HOST_GAME_LAUNCHER}${APIBASE}${
            CLIENT.VERIFY_THIRD_PARTY
        }?payload=${encodeURIComponent(payload.payload)}&signature=${encodeURIComponent(
            payload.signature,
        )}&refererUrl=${encodeURIComponent(refererUrl)}&oSession=${encodeURIComponent(
            oldSessionId,
        )}&oCurrency=${encodeURIComponent(oldCurrency)}&platform=${encodeURIComponent(platform)}`;
    } else if (pathname === ROUTES.CLIENT.SABA_AUTH) {
        URL = `${HOST_GAME_LAUNCHER}${APIBASE}${CLIENT.VERIFY_SABA}?funPlay=${encodeURIComponent(
            payload.FunPlay ?? false,
        )}&gameCode=${encodeURIComponent(payload.GameCode)}&homeUrl=${encodeURIComponent(
            payload.HomeUrl ?? '',
        )}&langCode=${encodeURIComponent(payload.LangCode ?? '')}&token=${encodeURIComponent(
            payload.Token,
        )}&refererUrl=${encodeURIComponent(refererUrl)}&oSession=${encodeURIComponent(
            oldSessionId,
        )}&oCurrency=${encodeURIComponent(oldCurrency)}`;
    }

    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: Object.keys(payload).length !== 0,
        url: URL,
    });

    useEffect(() => {
        configsService(response, error, pathname);

        if (error) {
            if (error.data) {
                //
            }
        }

        if (response) {
            if (
                response.headers[HTTP_HEADER.CHINA_IDENTIFIER] ||
                response.headers[HTTP_HEADER.CHINA_IDENTIFIER.toLocaleLowerCase()]
            ) {
                setLocalStorage(STORAGE.ISCHINA, true);
            } else {
                deleteFromLocalStorage(STORAGE.ISCHINA);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error]);

    return { error, loading, response };
}

export function useVerifyGameSetting(payload) {
    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: Object.keys(payload).length !== 0,
        url: `${HOST_GAME_LAUNCHER}${APIBASE}${
            CLIENT.VERIFY_GAME_SETTING
        }?payload=${encodeURIComponent(payload.payload)}&signature=${encodeURIComponent(
            payload.signature,
        )}`,
    });

    useEffect(() => {
        configsService(response);
        // update the user id

        if (response) {
            if (
                response.headers[HTTP_HEADER.CHINA_IDENTIFIER] ||
                response.headers[HTTP_HEADER.CHINA_IDENTIFIER.toLocaleLowerCase()]
            ) {
                setLocalStorage(STORAGE.ISCHINA, true);
            } else {
                deleteFromLocalStorage(STORAGE.ISCHINA);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error]);

    return { error, loading, response };
}
