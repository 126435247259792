import React from 'react';

import { Image } from './Image';
import { THEMES } from '../common/constants/misc';
import { getTranslation } from '../common/helpers/locale';

export default ({ theme = '', sessionInvalid = null, errorMsg = '' }) => {
    return (
        <div className="common-bg-box">
            <div className="preloader-box">
                <div className="preloader-box-loading-box">
                    <div className="error-pic-box-2">
                        {theme.toLocaleUpperCase() in THEMES.BLUE_THEME && (
                            <>
                                <Image
                                    className="animate-flicker icon"
                                    src="euro/warning-icon.png"
                                    alt="warning"
                                />
                                <br />
                                <span className="animate-flicker icon-text">
                                    {getTranslation('ERROR')}
                                </span>
                            </>
                        )}
                    </div>
                    <div>
                        <span className="error-text">
                            {getTranslation(sessionInvalid ? 'DISCONNECT' : 'SORRY')}...
                        </span>
                    </div>
                    <div className={`error-main-box ${sessionInvalid ? 'session-error-box' : ''}`}>
                        <span>
                            {getTranslation(sessionInvalid ? 'PLEASE_TRY_AGIAN' : 'PAGE_NOT_FOUND')}
                            {errorMsg !== '' && ` (${getTranslation('ERROR_CODE')} ${errorMsg})`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
