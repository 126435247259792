import { AppConstants } from '../scripts/constants/AppConstants';

let paths = {};
let service = {};

service.path = (key, params) => {
    let prefix = AppConstants.Service.HOST;

    let url = prefix + paths[key];
    if (params) {
        url += "?" + Object.keys(params).map(key => `${ key }=${ encodeURIComponent(params[key]) }`).join("&");
    }
    return url;
}

paths["auth/login"] = "/login";
paths["auth/authorize"] = "/userBySess";
paths["user/transactions"] = "/transactions";

export default service;