import { STORAGE } from '../constants/keys';

export const setLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (err) {
        console.log(err);
    }
};

export const getLocalStorage = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value === null) return undefined;
        return value;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

export const deleteFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (err) {
        console.log(err);
    }
};

//#region User
export const getCurrentUser = () => getLocalStorage(STORAGE.USER);

export const setUserInfo = (value) => {
    setLocalStorage(STORAGE.USER, value);
};

export const isUserTokenExpired = () => {
    const user = getCurrentUser();
    if (!user) return false;

    const expires = user.expires;
    const now = +new Date();

    return expires - now <= 10000;
};

export const isUserLoggedIn = () => !!getLocalStorage(STORAGE.USER);

export const deleteUserInfo = () => deleteFromLocalStorage(STORAGE.USER);

//#endregion

//#region Language
export const setLangLocalStorage = (value) => 
{
    setLocalStorage(STORAGE.LANGUAGE, value);
}

export const getLangLocalStorage = () => getLocalStorage(STORAGE.LANGUAGE);
//#endregion
