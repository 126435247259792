import { AppConstants } from '../constants/AppConstants';
import { AppDispatcher } from '../dispatcher/AppDispatcher';

var GameSettingsActions = {
    toggleTheme: function () {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.ActionTypes.TOGGLE_THEME,
        });
    },

    setTheme: function (theme) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.ActionTypes.SET_THEME,
            theme: theme,
        });
    },

    userMessage: function (type, message) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.ActionTypes.USER_MESSAGE,
            type: type,
            message: message,
        });
    },

    toggleSound: function (enable) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.ActionTypes.TOGGLE_SOUND,
            enable: enable,
        });
    },
};

export default GameSettingsActions;
