import { AppConstants } from '../../constants/AppConstants';
import { STORAGE } from '../constants/keys';
import { getLocalStorage } from './localStorage';

import { Clib } from '../../game-logic/clib';
import { THEMES } from '../constants/misc';
import { getTranslation } from './locale';
import _ from 'lodash';
import moment from 'moment';

export const isChina = () => getLocalStorage(STORAGE.ISCHINA);

export const getImageUrl = (image, checkExist) => {
    const prefix = isChina() ? AppConstants.CHINA_CDN.IMAGE : AppConstants.LOCAL_PATH.IMAGE;
    let imageUrl = prefix + image;

    // if image doesn't exist in cdn, use local image
    if (checkExist && !checkImageExists(imageUrl)) imageUrl = AppConstants.LOCAL_PATH.IMAGE + image;

    return imageUrl;
};

const checkImageExists = (image_url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);

    http.send();

    return http.status !== 404;
};

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const isLandscape = () => {
    return detectMobile() && window.innerWidth > window.innerHeight;
};

export function setIsLandscape(isLandscapeSite) {
    window.isLandscape = isLandscapeSite && isLandscape();
    // window.isLandscape = isLandscape();
}

export function getIsLandscape() {
    return window.isLandscape;
}

export const getVideoLinksByLanguage = (links, language) => {
    const langCode = language.split('-')[1];
    const lang = _.has(links, langCode) ? langCode : 'US';

    return isChina() ? links[lang].CN : links[lang].EN;
};

export const setIsDesktop = () => (window.isDesktop = !detectMobile());

export const isDesktop = () => window.isDesktop;

export const massageHistoryData = ({ game, Engine, currency }) => {
    var crashed = getTranslation('VOID');
    var crashedColor = 'white';
    var profit = getTranslation('VOID');
    if (game.status === 'COMPLETED') {
        crashed = Clib.formatSatoshis(game.game_crash);
        crashedColor = crashed > 2 ? 'blue' : 'yellow';
    }
    var buy = (Clib.getDisplayAmountByCurrency(currency, game.bet) / 100).toFixed();
    var sold = game.cash_out ? (game.cash_out / game.bet).toFixed(2) + 'x' : '-';
    if (game.status === 'COMPLETED' || sold !== '-') {
        profit = (game.cash_out ? game.cash_out : 0) + (game.bonus ? game.bonus : 0) - game.bet;
        var profitIcon = profit > 0 ? 'up' : 'down';

        profit =
            Engine.storage[STORAGE.THEME].toLocaleUpperCase() in THEMES.BLUE_THEME && profit < 0
                ? profit * -1
                : profit;
    }
    if (game.status === 'VOIDED' && sold === '-') {
        profit = '-';
    }
    var gameCreated = moment.utc(game.created).format('yyyyMMDD');
    var date = moment.utc(game.created).format('DD/MM/yyyy HH:mm:ss');
    var gameId = gameCreated + game.game_session_id;

    return {
        crashed,
        buy,
        sold,
        profitIcon,
        profit,
        date,
        gameId,
        crashedColor,
        voided: game.status === 'VOIDED',
    };
};
