import React from 'react';

import { IntlProvider } from './components/internationalisation/IntlProvider';

class GameMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = { module: null };
    }

    componentDidMount() {
        import(`${'../views/play'}`).then((module) => this.setState({ module: module.default }));
    }

    render() {
        const { module: Component } = this.state;
        return (
            <>
                <IntlProvider>{Component && <Component />}</IntlProvider>
            </>
        );
    }
}

export default GameMain;
