import React from 'react';

import * as QueryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { DEFAULT } from '../../common/constants/misc';
import { ROUTES } from '../../common/constants/routing';
import { setIsLandscape } from '../../common/helpers/misc';
import { useVerifyThirdParty } from '../../services/userService';
import CommonHandler from './CommonHandler';

export const CrashGameRedirection = ({ match, location }) => {
    const pathname = location.pathname;
    const params = QueryString.parse(location.search);

    let refererUrl = document.referrer;
    if (refererUrl === '' || refererUrl === undefined) {
        refererUrl = window.location.origin;
    }

    const { error, loading, response } = useVerifyThirdParty(params, refererUrl, pathname);
    setIsLandscape(pathname === ROUTES.CLIENT.SABA_AUTH);

    if (error) {
        return (
            <CommonHandler
                hasError={true}
                url={error.data ? error.data.backUrl : refererUrl}
                theme={error.data.result.theme ?? DEFAULT.THEME}
                errorCode={error.status}
                language={error.data.language}
            />
        );
    }
    if (loading || !response) {
        // return <CommonHandler url={refererUrl} theme={'blue'} />;
        return null;
    }
    if (response.data.isMaintenance) {
        return (
            <CommonHandler
                hasMaintenance={true}
                url={response.data.backUrl}
                theme={response.data.result.theme}
                language={response.data.result.language}
            />
        );
    }
    if (response.data === 'Hello from 3rd (terminal) Request Delegate') {
        return <CommonHandler hasError={true} url={refererUrl} />;
    }

    if (!loading && response) {
        import(`${'../../stores/GameSettingsStore'}`).then((module) => {});
    }

    return <Redirect to={{ pathname: '/' }} />;
};
