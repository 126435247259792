import { API } from '../common/constants/api';
import useApi from './hooks/useApi';

const { HOST, APIBASE, GAME_RESULT } = API;

export function useGetGameResult(payload) {
    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: Object.keys(payload).length !== 0,
        url: `${HOST}${APIBASE}${GAME_RESULT.SABA}?gameRoundId=${encodeURIComponent(
            payload.GameRoundId,
        )}&gameCode=${encodeURIComponent(payload.GameCode)}&token=${encodeURIComponent(
            payload.Token,
        )}&brand=${encodeURIComponent(payload.Brand)}`,
    });

    return { error, loading, response };
}
