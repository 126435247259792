import { AppConstants } from '../constants/AppConstants';
import Dispatcher from '../dispatcher/Dispatcher';
import _ from 'lodash';

export const AppDispatcher = _.extend(new Dispatcher(), {
    handleViewAction: function (action) {
        this.dispatch({
            source: AppConstants.PayloadSources.VIEW_ACTION,
            action: action,
        });
    },
});
