import { STORAGE } from '../common/constants/keys';
import {
    deleteFromLocalStorage,
    setLocalStorage,
    setUserInfo,
} from '../common/helpers/localStorage';

export function configsService(response, error, pathname) {
    var storage = {};
    deleteFromLocalStorage(STORAGE.CURRENCY);
    deleteFromLocalStorage(STORAGE.MINBUY);
    deleteFromLocalStorage(STORAGE.MAXBUY);
    deleteFromLocalStorage(STORAGE.INCREMENT);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON1);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON2);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON3);
    deleteFromLocalStorage(STORAGE.QUICKBUYBUTTON4);
    deleteFromLocalStorage(STORAGE.MAXAUTOSELL);
    deleteFromLocalStorage(STORAGE.USER);
    deleteFromLocalStorage(STORAGE.SIGNUPURL);
    deleteFromLocalStorage(STORAGE.LOGINURL);
    deleteFromLocalStorage(STORAGE.BACKURL);
    deleteFromLocalStorage(STORAGE.THEME);
    deleteFromLocalStorage(STORAGE.ANONYMOUS);
    deleteFromLocalStorage(STORAGE.GAMEID);
    deleteFromLocalStorage(STORAGE.CURRENCY_MULTIPLIER);

    if (error) {
        if (error.data) {
            letStorage(storage, STORAGE.BACKURL, error.data.backUrl);
            if (error.data.result) {
                letStorage(storage, STORAGE.THEME, error.data.result.theme);
                letStorage(storage, STORAGE.LANGUAGE, error.data.result.language);
            }
        }
        return;
    }

    if (response) {
        if (response.data.isBan || response.data.isDisconnect) {
            letStorage(storage, STORAGE.ACCOUNT_LOCKED, 'true');
        }
        if (response.data !== 'Hello from 3rd (terminal) Request Delegate') {
            if (
                !response.data.isAnonymous &&
                !response.data.isMaintenance &&
                !response.data.isBan &&
                !response.data.isDisconnect
            ) {
                letStorage(storage, STORAGE.CURRENCY, response.data.result.currency);
                letStorage(storage, STORAGE.CURRENCY_CODE, response.data.result.currencyCode);
                letStorage(storage, STORAGE.MINBUY, response.data.result.minBuy);
                letStorage(storage, STORAGE.MAXBUY, response.data.result.maxBuy);
                letStorage(storage, STORAGE.INCREMENT, response.data.result.increment);
                letStorage(storage, STORAGE.QUICKBUYBUTTON1, response.data.result.quickBuyButton1);
                letStorage(storage, STORAGE.QUICKBUYBUTTON2, response.data.result.quickBuyButton2);
                letStorage(storage, STORAGE.QUICKBUYBUTTON3, response.data.result.quickBuyButton3);
                letStorage(storage, STORAGE.QUICKBUYBUTTON4, response.data.result.quickBuyButton4);
                letStorage(storage, STORAGE.MAXAUTOSELL, response.data.result.maxAutoSell);
                letStorage(storage, STORAGE.USER, response.data.result.sessionId);
                letStorage(storage, STORAGE.GAMEID, response.data.result.gameId);
            }
            if (response.data.isAnonymous) {
                letStorage(storage, STORAGE.USER, response.data.result.sessionId);
                letStorage(storage, STORAGE.ANONYMOUS, true);
                letStorage(storage, STORAGE.CURRENCY, response.data.result.currency);
                letStorage(storage, STORAGE.CURRENCY_CODE, response.data.result.currencyCode);
                letStorage(storage, STORAGE.SIGNUPURL, response.data.signUpUrl);
                letStorage(storage, STORAGE.LOGINURL, response.data.loginUrl);
                letStorage(storage, STORAGE.MINBUY, response.data.result.minBuy);
                letStorage(storage, STORAGE.MAXBUY, response.data.result.maxBuy);
                letStorage(storage, STORAGE.INCREMENT, response.data.result.increment);
                letStorage(storage, STORAGE.QUICKBUYBUTTON1, response.data.result.quickBuyButton1);
                letStorage(storage, STORAGE.QUICKBUYBUTTON2, response.data.result.quickBuyButton2);
                letStorage(storage, STORAGE.QUICKBUYBUTTON3, response.data.result.quickBuyButton3);
                letStorage(storage, STORAGE.QUICKBUYBUTTON4, response.data.result.quickBuyButton4);
                letStorage(storage, STORAGE.MAXAUTOSELL, response.data.result.maxAutoSell);
                letStorage(storage, STORAGE.GAMEID, response.data.result.gameId);
            }
            letStorage(storage, STORAGE.THEME, response.data?.result.theme);
            letStorage(storage, STORAGE.LANGUAGE, response.data?.result.language);
            letStorage(storage, STORAGE.MAXPAYOUT, response.data?.result.maxPayout);
            letStorage(storage, STORAGE.DEFAULT_BUY, response.data?.result.defaultBuy);

            if (response.data.backUrl) {
                letStorage(storage, STORAGE.BACKURL, response.data.backUrl);
            }

            letStorage(
                storage,
                STORAGE.CURRENCY_MULTIPLIER,
                response.data.result.currencyMultiplier,
            );
        }
    }

    return storage;
}

const letStorage = (storage, key, value) => {
    storage[key] = value;
    switch (key) {
        case STORAGE.USER:
            setUserInfo(value);
            break;
        case STORAGE.GAMEID:
            setLocalStorage(STORAGE.GAMEID, value);
            break;
        case STORAGE.LANGUAGE:
        case STORAGE.THEME:
        case STORAGE.BACKURL:
        case STORAGE.CURRENCY:
        case STORAGE.ANONYMOUS:
        case STORAGE.MINBUY:
        case STORAGE.MAXBUY:
        case STORAGE.INCREMENT:
        case STORAGE.QUICKBUYBUTTON1:
        case STORAGE.QUICKBUYBUTTON2:
        case STORAGE.QUICKBUYBUTTON3:
        case STORAGE.QUICKBUYBUTTON4:
        case STORAGE.ENABLEAUTOSELL:
        case STORAGE.MAXAUTOSELL:
        case STORAGE.SIGNUPURL:
        case STORAGE.LOGINURL:
        case STORAGE.ACCOUNT_LOCKED:
            break;
        default:
            break;
    }
};
