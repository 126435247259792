import React from 'react';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.css';
import { CrashGameRedirection } from './scripts/components/third-party-redirect/CrashGameRedirection';
import { ROUTES } from './scripts/common/constants/routing';
import GameMain from './scripts/game';
import Launch from './scripts/components/Launch';
import NotFound from './views/404';
import Result from './scripts/components/Result';

function App() {
    const mainRoute = window.__POWERED_BY_QIANKUN__ ? ROUTES.CRASH.ROOT : ROUTES.LANDING;
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path={mainRoute} component={GameMain} />
                <Route exact path={ROUTES.CLIENT.AUTH} component={CrashGameRedirection} />
                <Route exact path={ROUTES.CLIENT.SABA_AUTH} component={CrashGameRedirection} />
                <Route exact path={ROUTES.CLIENT.LAUNCH} component={Launch} />
                <Route exact path={ROUTES.RESULT} component={Result} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
}

export default App;
