export const ROUTES = {
    NOT_FOUND: '/404',
    LANDING: '/',
    CRASH: {
        ROOT: '/crash',
    },
    CLIENT: {
        AUTH: '/client/auth',
        SABA_AUTH: '/LaunchGame',
        LAUNCH: '/launch',
    },
    RESULT: '/GetGameResult',
};
