import React from 'react';

import { Image } from './Image';
import { THEMES } from '../common/constants/misc';
import { getTranslation } from '../common/helpers/locale';

export default ({ theme = '' }) => {
    return (
        <div className="common-bg-box">
            <div className="preloader-box">
                <div className="preloader-box-loading-box">
                    <div className="maintenance-pic-box">
                        {theme.toLocaleUpperCase() in THEMES.BLUE_THEME && (
                            <Image
                                className="animate-flicker icon"
                                src="euro/tools-icon.png"
                                alt="tools"
                            />
                        )}
                    </div>
                    <div>
                        <span className="maintenance-text">
                            {getTranslation('SORRY_WE_RE_DOWN_FOR')}...
                        </span>
                    </div>
                    <div className="maintenance-main-box">
                        <span>{getTranslation('MAINTENANCE')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
