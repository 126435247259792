import _ from 'lodash';
import intl from 'react-intl-universal';

import { LANGUAGES } from '../constants/localization';

export const getLanguageByCode = (value) => {
    const foundLanguage = _.find(LANGUAGES, (l) => l.value === value);
    return foundLanguage;
};

export const getSupportedLocales = () => ({
    'zh-CN': require('../../locales/zh-CN.json'),
    'en-US': require('../../locales/en-US.json'),
    'vi-VN': require('../../locales/vi-VN.json'),
    'id-ID': require('../../locales/id-ID.json'),
    'th-TH': require('../../locales/th-TH.json'),
    'ko-KR': require('../../locales/ko-KR.json'),
    'hi-IN': require('../../locales/hi-IN.json'),
});

export const validLanguageCheck = (language) => {
    if (_.includes(['zh-CN', 'en-US', 'vi-VN', 'id-ID', 'th-TH', 'ko-KR', 'hi-IN'], language)) {
        return language;
    } else {
        return 'en-US';
    }
};

/**
 * Get translation text, return default text if translation not found
 * @param {String} sentence Sentence to translate
 */
export const getTranslation = (sentence, params = {}) => {
    if (sentence) return intl.get(sentence, params).defaultMessage(sentence);
};

export const getBrowserLocale = () => {
    if (navigator.languages) return navigator.languages[0];
    return navigator.language || navigator.userLanguage;
};
