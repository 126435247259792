import KeyMirror from '../lib/key-mirror';

export const AppConstants = {
    ActionTypes: KeyMirror({
        PLACE_BET: null,
        PLACE_BET_SUCCESS: null,
        PLACE_BET_ERROR: null,
        CANCEL_BET: null,
        CASH_OUT: null,
        SAY_CHAT: null,

        RUN_STRATEGY: null,
        STOP_SCRIPT: null,
        UPDATE_SCRIPT: null,
        SELECT_STRATEGY: null,
        SET_WIDGET_STATE: null,

        SELECT_TAB: null,

        SELECT_CONTROL: null,
        TOGGLE_CONTROL: null,

        SET_BET_SIZE: null,
        SET_AUTO_CASH_OUT: null,

        SET_STOP_WIN: null,
        SET_STOP_LOSE: null,

        SET_AUTO_BUY: null,

        SET_ROUNDS: null,

        SET_TOTAL_WIN_LOSE: null,

        SET_ADVANCED_START: null,

        SET_TAB: null,

        SET_CHAT_INPUT_TEXT: null,
        SET_CHAT_HEIGHT: null,
        IGNORE_USER: null,
        CLIENT_MESSAGE: null,
        APPROVE_USER: null,
        LIST_MUTED_USERS: null,
        SET_BOTS_DISPLAY_MODE: null,
        JOIN_CHANNEL: null,

        TOGGLE_THEME: null,
        SET_THEME: null,
        SET_CONTROLS_SIZE: null,
        SET_GRAPH_MODE: null,
        SET_CONTROLS_POSITION: null,
        SET_LEFT_WIDGET: null,
        TOGGLE_HOYTKEYS_STATE: null,

        TOGGLE_SOUND: null,

        DOUBLE_BET: null,
        HALF_BET: null,

        SELECT_CHART: null,

        SET_BET_STATUS: null,
    }),

    PayloadSources: KeyMirror({
        VIEW_ACTION: null,
    }),

    Engine: {
        STOP_PREDICTING_LAPSE: 300,
        HOST:
            process.env.NODE_ENV === 'development'
                ? 'ws://localhost:5000'
                : `wss://${window.location.host}${process.env.PUBLIC_URL}/socket`,
        CHAT_HOST: 'ws://localhost:3000',
        MAX_BET: 100000000,
        WON_MSG_INTERVAL: 3000,
        DISCONNECT_REASON: {
            DISCONNECT: 'DISCONNECTED',
            CHANGE_GAME: 'CHANGE_GAME',
            EXPIRED: 'EXPIRED',
        },
    },

    PROFILE_ID: 'id',

    BetButton: {
        INITIAL_DISABLE_TIME: 500,
    },

    Chat: {
        MAX_LENGTH: 500,
    },

    Animations: {
        NYAN_CAT_TRIGGER_MS: 115129,
    },

    Service: {
        HOST:
            process.env.NODE_ENV === 'development'
                ? 'http://localhost:4000'
                : `${window.location.origin}${process.env.PUBLIC_URL}/service`,
    },

    MinBetOptions: {
        stopWin: 0,
        stopLose: 0,
        betSize: 1,
        cashOut: 1.01,
        autoBuy: false,
        rounds: 0,
        advanced: {
            currentRound: 0,
            start: false,
        },
    },

    LOCAL_PATH: {
        CSS: {
            ROOT: process.env.PUBLIC_URL + '/assets/css/',
            CDN_FILE: '/assets/css/cdn-file/',
        },
        IMAGE: process.env.PUBLIC_URL + '/assets/images/',
        SOUND: process.env.PUBLIC_URL + '/assets/sounds/',
    },
    CHINA_CDN: {
        IMAGE: 'https://static-moon.pypc.net/assets/images/',
        SOUND: 'https://static-moon.pypc.net/assets/sounds/',
    },
};
